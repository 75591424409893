export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    image: {
      type: Object,
    },
    tools: {
      type: Array,
    },
  },

  computed: {
    hasImage() {
      return this.image ? true : false
    },
    hasTools() {
      return this.tools ? true : false
    },
  },
}
